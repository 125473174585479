import type { JSONContent } from '@tiptap/react'
import type { FloatingPanelPosition, FloatingPanelSize } from '@unbounded/unbounded-components'
import type { Dictionary, Market, Tickers } from 'ccxt'
import type { MiPasaPaginatedResult } from '~api/mipasa/backend'
import type { PreferencesTheme } from '~components/code/Editor/EditorTheme/EditorTheme.types'
import type { IsleDefinition } from '~components/code/IsleEditor/IsleEditor.types'
import type { NotebookCellMetadata, NotebookCellOutput } from '~components/code/NotebookEditor/NotebookEditor.types'
import type { NotebookStrategyAsset } from '~components/code/StrategyEditor/StrategyEditor.types'
import type { OpenAPISpec } from '~components/openapi/OpenAPI.types'
import type { Profile } from '~components/profile/slice'
import type { SliceState as SidebarPreferences } from '~containers/Sidebar/slice'
import type { CodeComments } from '~data/comment/comment-type'
import type { Dataset } from '~data/datasets'
import type { ExchangeType } from '~data/exchanges'
import type { Execution, ExecutionId, ExecutionSettings, ExecutionSummary } from '~data/execution/execution-type'
import type { File, FileId, FileVersion } from '~data/file/file-type'
import type { LastFilesPositions as LastFilesPositionPreferences } from '~data/last-files-position/last-files-position.types'
import type { ListingName, SortingDirection, TeamViewMode, ViewMode, ViewModeFilter } from '~data/listing'
import type { UserOnboardingState } from '~data/onboarding'
import type { Project, ProjectBasicInfo, ProjectPermission, ProjectSyncConfig } from '~data/projects'
import type { HTTPMethods } from '~utils/authorized-fetch'
import type { TrackingClientData } from '~utils/tracking'
import type BaseConnection from './connection'
import type { BaseConnectionListeners, ConnectionError } from './connection'

export interface UserSession {
  admin: boolean
  adminId?: string
  applicationId: string
  confirmed: boolean
  created: number
  ethereumSignature?: string
  expiration: number
  guestId?: string
  id: string
  ipAddress?: string
  lastActivity: number
  miPasaAuthorized: boolean
  remote: boolean
  remoteId?: string
  remoteUpdatedAt?: number
  roles: Array<string>
  userAgent?: string
  userId?: string
}

export interface User {
  avatar?: string
  avatarIcon?: string
  balance: number

  firstName: string
  lastName: string
  language?: string
  headline: string

  blogUrl: string
  websiteUrl: string
  linkedInUrl: string
  facebookUrl: string
  twitterUrl: string

  company: string
  companyPosition: string

  country: string

  preferences?: UserPreferences
  preferencesJson?: {}

  features?: Feature[]

  remoteId?: string
  remoteUpdatedAt?: string

  roles: Array<string>

  unboundedAvatar?: string
  unboundedUserId?: string
  unboundedUsername?: string

  githubAvatar?: string
  githubOAuthToken?: string
  githubPersonalAccessToken?: string
  githubUsername?: string

  slackTeamName?: string

  credentials: {
    email?: string
    confirmedAt?: number
    ethereumAddress?: string
    id: string
    name: string
    phone: string
    userId: string
  }

  id: string

  aboutNotebookFileId?: string

  isDiscoverable: boolean
  isSuspended: boolean

  isIDMManaged?: boolean
  isTelegramManaged?: boolean

  referralCode: string
}

export interface Self {
  token?: string
  commentoToken?: string
  session?: UserSession
  user?: User
  apiKey?: string
  signedIn: boolean
  sessionExpired: boolean
  documentationEnabled?: boolean
}

export interface Balance {
  userId?: string
  balance: number
  unboundedBalance: number
}

// this corresponds to historical parameter naming in UserView
export interface UserInfo {
  id: string
  username: string
  headline?: string
  avatar?: string
  avatarIcon?: string
  firstName?: string
  lastName?: string
  email?: string
  link?: string
  followingByYou?: boolean
  followersCount?: number
  aboutNotebookFileId?: string | null
  isDiscoverable: boolean
  referralCode: string
  isIDMManaged?: boolean
  isTelegramManaged?: boolean
}

export interface ResizablePreferences {
  version: number
  sizes: Array<number>
}

export interface ListingPreferences {
  pageSize: number
  sortBy: string
  sortDirection: string
  viewType: string
  mode: string
}

export interface NotificationsPreferences {
  isToastEnabled: boolean
  onlyShowUnread?: boolean
}

export interface FloatingPanelPreferences {
  size: FloatingPanelSize
  position: FloatingPanelPosition
}

export interface UserPreferences {
  onboarding?: UserOnboardingState
  sidebarVersion: number
  sidebar: SidebarPreferences
  resizable?: Record<string, ResizablePreferences>
  listing?: Record<ListingName, ListingPreferences>
  listingVersion: number
  notificationSettings?: NotificationsPreferences
  themeVersion: number
  theme: PreferencesTheme
  // A record of project id -> file id
  lastOpenedFiles?: Record<string, string | undefined>
  lastFilesPosition?: LastFilesPositionPreferences
  lastFilesPositionVersion: number
  isPublicProfileInfoMessageDismissed?: boolean
  floatingPanel?: {
    codeWidget?: FloatingPanelPreferences
    logicPanel?: FloatingPanelPreferences
  }
}

export interface NotificationsParams extends PaginationOptions {
  seen?: boolean
  type?: Array<string>
  typeExclude?: Array<string>
}

export enum ExportFormat {
  pdf = 'pdf',
  png = 'png',
  jpeg = 'jpeg',
}
export enum ExportTheme {
  light = 'light',
  dark = 'dark',
  print = 'print',
}

export interface ExportProjectFileOpts {
  includeOutputs?: boolean
  printTo?: ExportFormat
  printTheme?: ExportTheme
  printWidth?: number
  layoutHeaderText?: string
  layoutHeaderDate?: boolean
  layoutFooterText?: string
}

export interface Subscription {
  id: string
  expirationDate: string | null
  nextBillingDate: string | null
  canceledAt: string | null
  ratePlan: RatePlan
}

export interface Secret {
  id: string
  key: string
  secret: string
}

export type ScheduleIntervalType = 'minute' | 'hour' | 'day' | 'week' | 'month'

export interface ScheduledExecution {
  schedule_expression: {
    cron_expression: null | string
    interval?: number
    interval_type?: ScheduleIntervalType
    next_run_date?: null | string
  }
  enabled: boolean
  id: string
  starts_at: null | string
  continue_after: null | number
  days: number[]
  version?: {
    id: string
    link: string
  }
}

export interface ScheduledExecutionPatch {
  schedule_expression?: {
    interval: null | number
    interval_type: null | ScheduleIntervalType
  }
  days?: number[]
  enabled?: boolean
  starts_at?: null | string
  continue_after?: null | number
}

export enum ScheduledExecutionType {
  oneOff = 'one-off',
  recurring = 'recurring',
  continuous = 'continuous',
}

export enum PublicationScheduleType {
  oneOff = 'one-off',
  recurring = 'recurring',
}

export interface PublicationSchedule {
  type: PublicationScheduleType
  enabled?: boolean
  startsAt?: Date
  nextRunAt?: Date
  lastRunAt?: Date
  intervalType?: ScheduleIntervalType
  intervalValue?: number
  allowedDays?: number[]
}

export type RunStatus = 'in_progress' | 'ok' | 'error' | 'aborted'

export interface ScheduledRun {
  id: string
  status: RunStatus
  started_at: string
  completed_at: string | null
}

export interface ScheduledNotebook<UserType = UserInfo> extends ScheduledExecution {
  project: {
    id: string
    name: string
    owner: UserType
  }
  file: {
    id: string
    name: string
  }
  runs: ScheduledRun[]
  mode: 'private' | 'shared'
}

export interface UserServerSession {
  id: string
  last_activity_at: string
  execution_state: 'starting' | 'idle' | 'busy'
}

export interface UserServer {
  id: string
  image_id?: string
  execution_type?: 'on_demand' | 'scheduled'
  gpu?: boolean
  latest?: boolean
  ready: boolean
  started_at: string
  last_activity_at: string
  sessions: UserServerSession[]
}

export enum RatePlanName {
  free = 'Free',
  starter = 'Starter',
  pro = 'Pro',
  enterprise = 'Enterprise',
}

export enum RatePlanFeature {
  advancedEnvironmentsEnabled = 'advanced_environments_enabled',
  notebookThemeEnabled = 'notebook_theme_enabled',
  notebookInputCellEnabled = 'notebook_input_cell_enabled',
  cellExecutionTimeoutSeconds = 'cell_execution_timeout_seconds',
  notebookExecutionHistoryViewCount = 'notebook_execution_history_view_count',
  notebookExecutionMonthlyLimit = 'notebook_execution_monthly_limit',
  scheduledNotebooksCount = 'scheduled_notebooks_count',
  // Doesn't exist on BE yet
  portfolioRebalance = 'portfolio_rebalance',
}

export interface RatePlanFeatures {
  [RatePlanFeature.advancedEnvironmentsEnabled]: boolean
  [RatePlanFeature.notebookThemeEnabled]: boolean
  [RatePlanFeature.notebookInputCellEnabled]: boolean
  [RatePlanFeature.cellExecutionTimeoutSeconds]: number
  [RatePlanFeature.notebookExecutionHistoryViewCount]: number
  [RatePlanFeature.notebookExecutionMonthlyLimit]: number
  [RatePlanFeature.scheduledNotebooksCount]: number | 'infinity'
  [RatePlanFeature.portfolioRebalance]: boolean
}

export interface RatePlan {
  id: string
  isHighlighted: boolean
  priceInUnits: number
  title: RatePlanName
  details: string
  features: RatePlanFeatures
}

export interface EventEntry {
  resourceId: string
  type: string
}

interface EventDataBase {
  resourceId: string
  clientData?: TrackingClientData
}

interface EventDataNotebookViewed extends EventDataBase {
  type: 'notebook.viewed'
}

interface EventDataDocumentationViewed extends EventDataBase {
  type: 'documentation.viewed'
  sectionId: string
}

interface EventDataPublicationViewed extends EventDataBase {
  type: 'publication.viewed'
  referralCode?: string
}

interface EventDataSubscribedToNewsletter {
  type: 'user.subscribed_to_newsletter'
  email: string
  firstName: string
  lastName: string
}

export type EventData = EventDataNotebookViewed | EventDataDocumentationViewed | EventDataPublicationViewed | EventDataSubscribedToNewsletter

export interface PaginationOptions {
  perPage?: number
  page?: number
}

export interface SortingOptions<T> {
  sortingField?: T
  sortingDirection?: SortingDirection
}

export interface FetchTeamsPaginationOptions extends PaginationOptions, SortingOptions<string> {
  mode?: TeamViewMode
  filter?: string
  memberId?: string
  membersCount?: number
}

export interface FetchExecutionsOptions extends PaginationOptions {
  mode?: 'all' | 'scheduled' | 'on_demand'
  search?: string
}

export interface ProjectPaginationOptions extends PaginationOptions, SortingOptions<string> {
  mode?: ViewMode
  modeFilters?: ViewModeFilter[]
  filter?: string
  ownerId?: string
  authorId?: string
  tags?: string[]
  section?: 'general' | 'featured' | 'portfolios'
}

export interface FetchPermissionsPaginationOptions extends PaginationOptions, SortingOptions<string> {
  filter?: string
}

export interface FetchMembersPaginationOptions extends PaginationOptions, SortingOptions<string> {
  filter?: string
}

export interface FetchWalletPaginationOptions extends PaginationOptions, SortingOptions<string> {
  filter?: string
}

export interface PublicationPaginationOptions extends PaginationOptions, SortingOptions<string> {
  mode?: ViewMode
  filter?: string
  tags?: string[]
  type?: PublicationType
  aiQuery?: unknown
  owner?: string
}

export interface PaginatedResult<Item> {
  entries: Array<Item>
  page: number
  perPage: number
  totalPages: number
  totalEntries?: number
}

export interface ProjectPaginatedResult extends PaginatedResult<Project> {
  tags: Array<Tag>
}

export interface PublicationPaginatedResult extends PaginatedResult<Publication> {
  tags: Array<Tag>
}

export interface ResponseCollection<Item> {
  entries: Array<Item>
}

export interface BackendSortingOption {
  title: string
  field: string
  internalField: string
  shortTitle?: string
}

export interface BackendSortingInfo {
  fields: Array<BackendSortingOption>
  defaultField: BackendSortingOption
}

export interface BackendSelectOption<K> {
  key: K
  value: string
  caption?: string
}

export interface BackendSelectInfo<K> {
  options: Array<BackendSelectOption<K>>
  defaultOption: BackendSelectOption<K>
}

export interface BackendPermissionOption<V> {
  label: string
  value: Array<V>
  isTopSeparated?: boolean
}

export interface BackendPermissionInfo<V> {
  options: Array<BackendPermissionOption<V>>
  defaultOption: BackendPermissionOption<V>
}

export interface BackendCollaborationConnectionListeners extends BaseConnectionListeners {
  status: (object: any) => void
  state: (object: any) => void
  add_user: (object: any) => void
  remove_user: (object: any) => void
  broadcast: (object: any) => void
}

export interface BackendCollaborationConnection extends BaseConnection<BackendCollaborationConnectionListeners> {
  sendBroadcast(object: any): void
}

export interface BackendExecutionConnectionListeners extends BaseConnectionListeners {
  output: (executionId: string, output: string) => void
}

export interface BackendExecutionConnection extends BaseConnection<BackendExecutionConnectionListeners> {
  sendInput(input: string): void
}

export interface BackendContainerConnectionListeners extends BaseConnectionListeners {
  response: (response: string, payload: any) => void
}

export interface BackendContainerConnection extends BaseConnection<BackendContainerConnectionListeners> {
  sendCommand(command: string, payload: any): void
}

export interface BackendNotebookConnectionParameters {
  viewOnly?: boolean
  inputMutation?: boolean
  debugMode?: boolean
  withLastOkRun?: boolean
  versionId?: string | null
  publicationId?: string
}

export enum BackendNotebookConnectionCellType {
  code = 'code',
  raw = 'text',
  markdown = 'markdown',
}

export enum BackendNotebookConnectionViewOnlyReason {
  requested = 'requested',
  noPermissions = 'no_permissions',
  locked = 'locked',
}

export interface BackendNotebookConnectionLockedByUser {
  self: boolean
  id?: string
  user_name?: string
}

export interface BackendNotebookConnectionCellPayload {
  collapsed: boolean
  id: string
  metadata: NotebookCellMetadata
  number: number
  outputs: Array<NotebookCellOutput>
  source: string
  status: string
  type: BackendNotebookConnectionCellType
}

export interface BackendNotebookConnectionClientPayload {
  id: string
  self: boolean
  username: string
}

export interface BackendNotebookConnectionClientCellPayload {
  tag: string
  cell_id: string | null
}

export interface BackendNotebookConnectionClientCellUpdatePayload extends BackendNotebookConnectionClientCellPayload {
  client: BackendNotebookConnectionClientPayload
}

export interface BackendNotebookConnectionClientFullPayload {
  client: BackendNotebookConnectionClientPayload
  cells: BackendNotebookConnectionClientCellPayload[]
}

export type BackendNotebookConnectionExecutionStatus = 'executing' | 'preparing' | 'pending_execution' | 'idle'

export interface BackendNotebookConnectionNotebookPayload {
  notebook: {
    cells: BackendNotebookConnectionCellPayload[]
    environment: string
    gpu: boolean
    autorun_after_files_updated: boolean
    id: string
    theme: string
    metadata: Record<string, any>
    version_id: string | null
  }
  view_only_reason: BackendNotebookConnectionViewOnlyReason | null
  execution_status: BackendNotebookConnectionExecutionStatus | null
  executed_all: boolean | null
  clients: BackendNotebookConnectionClientFullPayload[] | null
  instance_id: string
}

export interface BackendNotebookConnectionCellOutputPayload {
  cell_id: string
  output: NotebookCellOutput
  output_index: number
}

export interface BackendNotebookConnectionSaveStatusPayload {
  status: 'saving' | 'saved'
}

export interface BackendNotebookConnectionExecutionStatusPayload {
  preparation_stage: string
  status: BackendNotebookConnectionExecutionStatus
  executed_all?: boolean
}

export interface BackendNotebookConnectionCellInsertedPayload {
  cell: BackendNotebookConnectionCellPayload
  all_cell_ids: string[]
}

export interface BackendNotebookConnectionCellUpdatedPayload {
  cell_id: string
  type?: BackendNotebookConnectionCellType
  source?: string
  metadata?: NotebookCellMetadata
  collapsed?: boolean
}

export interface BackendNotebookConnectionCellStatusPayload {
  cell_id: string
  status: 'pending' | 'executing' | 'ok' | 'error' | null
}

export interface BackendNotebookConnectionCellMovedPayload {
  cell_id: string
  all_cell_ids: string[]
}

export interface BackendNotebookConnectionCellDeletedPayload {
  cell_id: string
  all_cell_ids: string[]
}

export interface BackendNotebookConnectionNotebookUpdatedPayload {
  environment?: string
  theme?: string
  gpu?: boolean
  autorun_after_files_updated?: boolean
  metadata?: Record<string, any>
}

export interface BackendNotebookConnectionEnvironmentPayload {
  environment: string
}

export interface BackendNotebookConnectionRequestErrorPayload {
  reason: string
  request: string
}

export interface VarData {
  varName: string
  varType: string
  varTypeModule: string
  varSize: string
  varContent: string
  varShape: string
}

export interface BackendNotebookConnectionExecuteCodeSnippetPayload {
  data: Array<NotebookCellOutput>
}

export interface BackendNotebookConnectionVarsListPayload {
  data: Array<VarData>
}

export interface BackendNotebookConnectionDataframeColumnsPayload {
  data?: Record<string, string>
  error?: string
}

export interface BackendNotebookConnectionExcelSheetsPayload {
  data?: Array<string>
  error?: string
}

export interface BackendNotebookConnectionCompletionPayload {
  text: string
  type: string | null
  signature: string | null
  range: [number, number]
}

export interface BackendNotebookConnectionCompletionsPayload {
  completions: Array<BackendNotebookConnectionCompletionPayload>
}

export interface BackendNotebookConnectionVersionUpdatedPayload {
  version_id: string
}

export interface BackendNotebookConnectionLockTakenOverPayload {
  locked_by_user: BackendNotebookConnectionLockedByUser
}

export interface BackendNotebookConnectionStatsAndAlertsPayload {
  stats: {
    static: {
      mem_total: number
      cpu_count: number
    }
    samples: [
      datetime: string,
      sample: {
        system: {
          mem: number
          cpus: number
        }
        kernels: {
          [kernel_id: string]: null | {
            cpu: number
            mem: number
          }
        }
      },
    ][]
  }
  alerts: {
    high_mem: boolean | 'new'
  }
}

export interface BackendNotebookConnectionListeners extends BaseConnectionListeners {
  init: (payload: BackendNotebookConnectionNotebookPayload) => void
  init_error: (error: ConnectionError) => void
  client_joined: (payload: BackendNotebookConnectionClientPayload) => void
  client_left: (payload: BackendNotebookConnectionClientPayload) => void
  client_cell_updated: (payload: BackendNotebookConnectionClientCellUpdatePayload) => void
  save_status_changed: (payload: BackendNotebookConnectionSaveStatusPayload) => void
  version_saved: (payload: BackendNotebookConnectionVersionUpdatedPayload) => void
  version_restored: (payload: BackendNotebookConnectionVersionUpdatedPayload) => void
  execution_status_changed: (payload: BackendNotebookConnectionExecutionStatusPayload) => void
  cell_inserted: (payload: BackendNotebookConnectionCellInsertedPayload) => void
  cell_updated: (payload: BackendNotebookConnectionCellUpdatedPayload) => void
  cell_status_changed: (payload: BackendNotebookConnectionCellStatusPayload) => void
  cell_output_added: (payload: BackendNotebookConnectionCellOutputPayload) => void
  cell_moved: (payload: BackendNotebookConnectionCellMovedPayload) => void
  cell_deleted: (payload: BackendNotebookConnectionCellDeletedPayload) => void
  notebook_updated: (payload: BackendNotebookConnectionNotebookUpdatedPayload) => void
  execution_process_restarting: () => void
  execution_stats_and_alerts: (payload: BackendNotebookConnectionStatsAndAlertsPayload) => void
  execution_environment_selected: (payload: BackendNotebookConnectionEnvironmentPayload) => void
  execution_environment_not_found: (payload: BackendNotebookConnectionEnvironmentPayload) => void
  embed_updated: (payload: BackendConnectionDependency) => void
  request_error: (payload: BackendNotebookConnectionRequestErrorPayload) => void
  connection_error: (error: ConnectionError) => void
}

export interface BackendNotebookConnectionCellOutputOpts {
  page?: number
  pageSize?: number
}

export interface BackendNotebookConnection extends BaseConnection<BackendNotebookConnectionListeners> {
  fetchNotebook(): Promise<BackendNotebookConnectionNotebookPayload>
  fetchCellOutput(
    cell_id: string,
    output_index: number,
    mime_type?: string,
    opts?: BackendNotebookConnectionCellOutputOpts,
  ): Promise<BackendNotebookConnectionCellOutputPayload>
  sendStartEditing(): Promise<BackendNotebookConnectionNotebookPayload>
  sendStartEditingCell(cell_id?: string, tag?: string, takeover?: boolean): void
  fetchExecuteCodeSnippet(code: string): Promise<BackendNotebookConnectionExecuteCodeSnippetPayload | null>
  fetchVarsList(): Promise<BackendNotebookConnectionVarsListPayload | null>
  fetchDataframeColumns(dataframeVar: string): Promise<BackendNotebookConnectionDataframeColumnsPayload | null>
  fetchExcelSheets(filename: string): Promise<BackendNotebookConnectionExcelSheetsPayload | null>
  fetchCompletions(code: string, cursorPos: number): Promise<BackendNotebookConnectionCompletionsPayload>
  sendInsertCell(cell_id: string, index: number, type: BackendNotebookConnectionCellType): void
  sendDeleteCell(cell_id: string): void
  sendMoveCell(cell_id: string, index: number): void
  sendSetCellType(cell_id: string, type: BackendNotebookConnectionCellType): void
  sendUpdateCellSource(cell_id: string, source: string): void
  sendUpdateCellMetadata(cell_id: string, metadata: NotebookCellMetadata): void
  sendCollapseCell(cell_id: string, collapsed: boolean): void
  sendCollapseAllCells(collapsed: boolean): void
  sendExecuteAllCells(clean: boolean): void
  sendExecuteCell(cell_id: string, clean: boolean): void
  sendExecuteCells(cell_ids: string[], clean: boolean, with_deps?: boolean): void
  sendClean(): void
  sendInterrupt(): void
  sendSelectEnvironment(environment: string | null): void
  sendToggleGPU(gpu: boolean): void
  sendToggleAutorunAfterFilesUpdated(autorunAfterFilesUpdated: boolean): void
  sendUpdateMetadata(metadata: Record<string, any>, shouldReplace: boolean): void
  sendSelectTheme(theme: string): void
  sendSaveVersion(): void
  sendRestoreVersion(versionId: string): void
}

export interface BackendConnectionDependency {
  id: string
  file_id: string
  cell_id: string
  embed_type: 'outputs' | 'markdown'
  outputs?: Array<NotebookCellOutput>
  markdown?: string
  metadata: NotebookCellMetadata
  latest_outputs: boolean
  dependency_exists: boolean
}

export enum BackendCellEmbedConnectionMode {
  last_ok = 'last_ok',
  scheduled = 'scheduled',
  latest = 'latest',
}

export interface BackendCellEmbedConnectionUpdate {
  id: string
  file_id: string
  project_id: string
  metadata: NotebookCellMetadata
  output_index: number
  output: NotebookCellOutput | null
}

export interface BackendCellEmbedConnectionListeners extends BaseConnectionListeners {
  init: (_payload: {}) => void
  init_error: (error: ConnectionError) => void
  cell_updated: (payload: BackendCellEmbedConnectionUpdate) => void
  no_content: () => void
}

export interface BackendCellEmbedConnection extends BaseConnection<BackendCellEmbedConnectionListeners> {
  fetchCellOutputPage(page: number, pageSize: number): Promise<BackendCellEmbedConnectionUpdate>
}

export interface NewsroomEntry {
  id: string
  image: string | null
  is_featured: boolean
  name: string
  published_at: string
  url: string
}

export interface NewsroomEntryUpdate {
  image?: Blob | null
  name: string
  published_at: string
  url: string
}

export interface DocumentationSection {
  id: string
  index: number
  slug: string
  publication: Publication
}

export interface DocumentationSectionUpdate {
  index: number
  slug: string
}

export interface Referral {
  id: string
  inserted_at: string
  code: string
  units: number
  enabled: boolean
}

export interface ReferralUser {
  id: string
  firstName?: string
  lastName?: string
  userName: string
  insertedAt: Date
}

export interface ReferralUpdate {
  code: string
  units?: number
  enabled?: boolean
}

export interface UserToken {
  token: string | undefined
}

export interface FileEmbedOptions {
  isExecutionEnabled: boolean
  isFileCopyEnabled: boolean
}

export interface FileEmbed extends FileEmbedOptions {
  id: string
}

export interface BackendNotificationsConnectionNotificationsUpdatedPayload {
  unread_count: number
}

export interface BackendNotificationsConnectionListeners extends BaseConnectionListeners {
  init: (payload: object) => void
  init_error: (error: ConnectionError) => void
  notification_created: (payload: unknown) => void
  notifications_updated: (payload: BackendNotificationsConnectionNotificationsUpdatedPayload) => void
}

export interface BackendNotificationsConnection extends BaseConnection<BackendNotificationsConnectionListeners> {
  readNotification(id: string): void
}

export interface BackendProjectImportConnectionImportProgressPayload {
  stage: string
  stage_percent: number | null
  total_percent: number
  abortable: boolean
}

export interface BackendProjectImportConnectionImportResultOkPayload {
  status: 'ok'
  project_id: string
}

export interface BackendProjectImportConnectionImportResultErrorPayload {
  status: 'error'
  reason: string
  details?: string
}

export interface BackendProjectImportConnectionImportResultAbortedPayload {
  status: 'aborted'
}

export enum ProjectSyncProvider {
  Git = 'git',
  GitHub = 'github',
  GitLab = 'gitlab',
}

export interface ProjectSyncProviderConfig {
  accessToken?: string
  instanceUrl?: string
}

export interface BackendProjectImportConnectionChangeConfigRequestPayload {
  provider: ProjectSyncProvider
  providerConfig?: ProjectSyncProviderConfig
  repo?: string | null
  branch?: string | null
  directory?: string | null
}

export interface BackendProjectImportConnectionBrowseRemoteResponsePayload {
  entries: string[]
  type: 'repos' | 'branches' | 'directories'
}

export type BackendProjectImportConnectionImportResultPayload =
  | BackendProjectImportConnectionImportResultOkPayload
  | BackendProjectImportConnectionImportResultErrorPayload
  | BackendProjectImportConnectionImportResultAbortedPayload

export interface BackendProjectImportConnectionStartImportRequestPayload {
  name?: string
}

export interface BackendProjectImportConnectionListeners extends BaseConnectionListeners {
  init: (payload: object) => void
  init_error: (error: ConnectionError) => void
  import_progress: (payload: BackendProjectImportConnectionImportProgressPayload) => void
  import_result: (payload: BackendProjectImportConnectionImportResultPayload) => void
}

export interface BackendProjectImportConnection extends BaseConnection<BackendProjectImportConnectionListeners> {
  changeConfig(config: BackendProjectImportConnectionChangeConfigRequestPayload): void
  browseRemote(): Promise<BackendProjectImportConnectionBrowseRemoteResponsePayload>
  startImport(opts: BackendProjectImportConnectionStartImportRequestPayload): void
  abortImport(): void
}

export enum BackendCollaboratorLobbyConnectionPresenceActivity {
  viewing = 'viewing',
  editing = 'editing',
}

export interface BackendCollaboratorLobbyConnectionPresenceCustomMeta {
  id?: string
  filename?: string
  activity?: BackendCollaboratorLobbyConnectionPresenceActivity
}

export interface BackendCollaboratorLobbyConnectionPresenceMeta extends BackendCollaboratorLobbyConnectionPresenceCustomMeta {
  phx_ref: string
  username: string
}

export interface BackendCollaboratorLobbyConnectionPresenceUserInfo {
  metas: BackendCollaboratorLobbyConnectionPresenceMeta[]
}

export type BackendCollaboratorLobbyConnectionPresenceUsers = Record<string, BackendCollaboratorLobbyConnectionPresenceUserInfo>

export interface BackendCollaboratorLobbyConnectionPresenceDiff {
  joins: BackendCollaboratorLobbyConnectionPresenceUsers
  leaves: BackendCollaboratorLobbyConnectionPresenceUsers
}

export interface BackendCollaboratorLobbyConnectionFileChangePayload {
  operation: 'created' | 'updated' | 'moved' | 'deleted'
  file_id: string
  file_names: string[]
}

export interface BackendCollaboratorLobbyConnectionListeners extends BaseConnectionListeners {
  init: (payload: object) => void
  init_error: (error: ConnectionError) => void
  presence_state: (users: BackendCollaboratorLobbyConnectionPresenceUsers) => void
  presence_diff: (diff: BackendCollaboratorLobbyConnectionPresenceDiff) => void
  file_change: (payload: BackendCollaboratorLobbyConnectionFileChangePayload) => void
}

export interface BackendCollaboratorLobbyConnection extends BaseConnection<BackendCollaboratorLobbyConnectionListeners> {
  updateMeta(meta: BackendCollaboratorLobbyConnectionPresenceCustomMeta): void
}

export interface TrackingEventPageView {
  type: 'pageview'
  url: string
  title?: string
  clientData?: TrackingClientData
}

export type TrackingEvent = TrackingEventPageView

export interface BackendTrackingConnection extends BaseConnection<BaseConnectionListeners> {
  sendEvent(event: TrackingEvent): void
}

// @see https://developers.binance.com/docs/binance-spot-api-docs/enums#order-side-side
export enum TradingTransactionOrderSide {
  buy = 'buy',
  sell = 'sell',
}

// @see https://developers.binance.com/docs/binance-spot-api-docs/enums#order-types-ordertypes-type
export enum TradingTransactionOrderType {
  LIMIT = 'LIMIT',
  MARKET = 'MARKET',
  STOP_LOSS = 'STOP_LOSS',
  STOP_LOSS_LIMIT = 'STOP_LOSS_LIMIT',
  TAKE_PROFIT = 'TAKE_PROFIT',
  TAKE_PROFIT_LIMIT = 'TAKE_PROFIT_LIMIT',
  LIMIT_MAKER = 'LIMIT_MAKER',
}

// Only CCXT statuses that we expect.
export enum TradingTransactionOrderStatus {
  // template was produced, but order was not sent
  formed = 'formed',
  // order was sent and processed
  closed = 'closed',
  // order is not yet complete
  open = 'open',
  // order was processed partially (or not) and then canceled
  canceled = 'canceled',
  // order was processed partially (or not) and then expired
  expired = 'expired',
  // order was rejected from processing
  rejected = 'rejected',
}

export interface TradingTransactionOrder {
  id?: number | string
  side: TradingTransactionOrderSide
  action?: TradingTransactionOrderSide
  type: TradingTransactionOrderType
  // We expect only filled orders here for now
  status: TradingTransactionOrderStatus
  // Base asset
  // e.g. `BTC`
  asset: string
  // Market symbol
  // e.g. `BTCUSDT`
  symbol: string
  // Base asset traded amount absolute value
  // e.g. `0.025` (BTC)
  amount?: number
  // Base asset filled amount (i.e. how much was actually handled)
  filled?: number
  // Quote asset traded amount absolute value
  // e.g. `50` (USDT)
  cost?: number
  // Quote asset limit ordered amount absolute value
  // e.g. `50` (USDT)
  costLimit?: number
  // Base asset price at the moment of order
  // e.g. `56` (56 USDT per 1 BTC)
  price: number
  // Fees info, will be only 1 item in general,
  // but might be more if paid in multiple currencies
  fees?: Array<TradingTransactionOrderFee>
  /**
   * Fee info, will be remapped to `fees` as `[fee]` if `fees` is missing.
   * Not to be used separately
   * @deprecated Use `fees` array instead
   */
  fee?: TradingTransactionOrderFee
}

/**
 * Fee info from CCXT
 * @see https://github.com/ccxt/ccxt/wiki/Manual#order-structure
 */
export interface TradingTransactionOrderFee {
  // Total fee amount absolute value
  // e.g. `2`
  cost: number
  // Fee asset
  // e.g. `USDT`
  currency: string
  // Fee rate (if available)
  // e.g. `0.002`
  rate?: number
}

export type TradingTransactionStatus = 'ok' | 'error' | 'pending' | 'running' | 'aborted'

// @deprecated use `orders` instead
// [order side, market symbol, original amount]
// e.g. ['buy', 'BTCUSDT', 50]
// TODO remove once `orders` are added to BE response
// https://hacera.atlassian.net/browse/PASA-248
type TradingTransactionAction = ['buy' | 'sell', string, number]

interface TradingTransactionBase {
  id: string
  status: TradingTransactionStatus
  message: string | null
  inserted_at: string
  updated_at: string
  project_id: string
  user_id: string
  project?: ProjectBasicInfo
  metadata: {
    exchange?: ExchangeType
    // Quote asset
    // e.g. `USDT`
    quoteAsset?: string
    errors?: Record<string, string>
    orders?: Record<string, TradingTransactionOrder>
    prices?: Record<string, number>
    actions?: Array<TradingTransactionAction>
  }
}

interface TradingTransactionDeposit extends TradingTransactionBase {
  type: 'deposit'
  metadata: TradingTransactionBase['metadata'] & {
    // Total original deposited (spent) quote asset amount absolute value
    // e.g. `200`
    amount?: number
    // Total executed deposited (spent) quote asset amount absolute value
    // e.g. `200.5042`
    executedAmount?: number
  }
}

interface TradingTransactionWithdraw extends TradingTransactionBase {
  type: 'withdraw'
  metadata: TradingTransactionBase['metadata'] & {
    // Total original withdrawn (obtained) quote asset amount absolute value
    // e.g. `200`
    amount?: number
    // Total executed withdrawn (obtained) quote asset amount absolute value
    // e.g. `200.5042`
    executedAmount?: number
  }
}

export type TradingTransaction = TradingTransactionDeposit | TradingTransactionWithdraw
export type TradingTransactionPaginatedResult = MiPasaPaginatedResult<TradingTransaction>

export interface TradingTransactions {
  transactions: TradingTransactionPaginatedResult
  totals: Record<string, number>
  positive_totals: Record<string, number>
  negative_totals: Record<string, number>
}

export interface TradingTransactionsConnectionListeners extends BaseConnectionListeners {
  init: (payload: TradingTransactions) => void
  page: (page: TradingTransactions) => void
}

export interface BackendTradingTransactionsConnection extends BaseConnection<TradingTransactionsConnectionListeners> {
  requestPage(page: number, perPage?: number): void
}

export interface BackendFileChannelLockResponse {
  locked: boolean
  self: boolean
  locker_user_name: string | null
}

export interface BackendFileChannelConnectionListeners extends BaseConnectionListeners {
  init: (payload: BackendFileChannelLockResponse) => void
  init_error: (error: ConnectionError) => void
  locker_changed: (resp: BackendFileChannelLockResponse) => void
}

export interface BackendFileChannelConnection extends BaseConnection<BackendFileChannelConnectionListeners> {
  takeover(): void
}

export interface ProjectAccessRequest {
  id: string
  user: UserInfo
  createdAt: number
  reason: string
  status: string
}

// We need to merge/map/whatever it with `ProjectPermission` somehow
export enum NotebookPermission {
  view = 'view',
  viewSource = 'view_source',
  editSource = 'edit',
  execute = 'execute',
  clone = 'clone',
  // delete = 'delete',
  export = 'export',
  exportData = 'export_data',
  share = 'share',
  // schedule = 'schedule',
  publish = 'publish',
  viewAnalytics = 'view_analytics',
  viewHistory = 'view_history',
  embed = 'embed',
}

export interface NotebookAnalysis {
  nodes: NotebookAnalysisNode[]
}

export type NotebookAnalysisNode = NotebookAnalysisNodeNotebook | NotebookAnalysisNodeDataset | NotebookAnalysisNodeExternalDataset

interface NotebookAnalysisNodeBase {
  id: string
  parent_id?: string | null
}

export interface NotebookAnalysisNodeNotebook extends NotebookAnalysisNodeBase {
  type: NotebookAnalysisNodeType.notebook
  entity: NotebookAnalysisEntityNotebook
}

export interface NotebookAnalysisNodeDataset extends NotebookAnalysisNodeBase {
  type: NotebookAnalysisNodeType.dataset
  entity: NotebookAnalysisEntityDataset
}

export interface NotebookAnalysisNodeExternalDataset extends NotebookAnalysisNodeBase {
  type: NotebookAnalysisNodeType.externalDataset
  entity: NotebookAnalysisEntityExternalDataset
}

export type NotebookAnalysisEntity = NotebookAnalysisEntityNotebook | NotebookAnalysisEntityDataset | NotebookAnalysisEntityExternalDataset

export interface NotebookAnalysisEntityBase {
  id: string
}

export interface NotebookAnalysisEntityNotebook extends NotebookAnalysisEntityBase {
  name: string
  owner: UserInfo
  thumbnail: {
    icon_url?: string
    original_url?: string
  }
  updated_at: number
}

export interface NotebookAnalysisEntityDataset extends NotebookAnalysisEntityBase {
  name: string
  owner: UserInfo
  thumbnail: {
    icon_url?: string
    original_url?: string
  }
  files: string[]
}

export interface NotebookAnalysisEntityExternalDataset extends NotebookAnalysisEntityBase {
  file_ids: string[]
  version: number
  dataset?: Dataset
}

export enum NotebookAnalysisNodeType {
  notebook = 'notebook',
  dataset = 'dataset',
  externalDataset = 'external_dataset',
}

export enum NotebookAnalysisDirection {
  input = 'input',
  output = 'output',
}

export interface ProjectUserAccess {
  id: string
  permissions: NotebookPermission[]
  user: UserInfo
  author: boolean
  createdAt: number | string
  isOwner: boolean
}

export interface ProjectTeamAccess {
  id: string
  permissions: NotebookPermission[]
  team: Team
  createdAt: number | string
}

export interface ProjectInvite {
  id: string
  email: string
  permissions: Array<NotebookPermission>
  createdAt: string
}

export enum ScheduledNotebookListingTimeFilter {
  All = 'all',
  Past = 'past',
  Current = 'current',
  Future = 'future',
}

export enum ScheduledNotebookListingActivationFilter {
  All = 'all',
  Enabled = 'enabled',
  Disabled = 'disabled',
}

export enum ScheduledNotebookListingRecurrenceFilter {
  All = 'all',
  OneOff = 'one_off',
  Recurring = 'recurring',
  Continuous = 'continuous',
}

export enum ScheduledNotebookListingSortBy {
  LastRun = 'last_run',
  NextRun = 'next_run',
  Enabled = 'enabled',
  NotebookName = 'project_name',
  Owner = 'owner',
}

export interface ScheduledNotebookListingOptions extends PaginationOptions, SortingOptions<ScheduledNotebookListingSortBy> {
  time?: ScheduledNotebookListingTimeFilter
  activation?: ScheduledNotebookListingActivationFilter
  recurrence?: ScheduledNotebookListingRecurrenceFilter
  adminMode?: boolean
  ownerSearch?: string
}

export interface Tag {
  id: string
  name: string
}

export interface TagSubscription {
  email: string
  id: string
  tag: Tag
}

export interface Team {
  id: string
  name: string
  permissions: Array<TeamMemberPermission>
  icon?: string
  createdAt: number
  updatedAt: number
  createdBy: UserInfo
  usersCount: number
  projectsCount: number
  isDiscoverable: boolean
  approvableJoin: boolean
  description?: string
  contactEmail?: string
  membershipStatus?: TeamMembershipStatus
  lastMembers?: Array<{ user: UserInfo; permissions: Array<'manage' | 'view'> }>
}

export enum TeamMembershipStatus {
  owner = 'owner',
  admin = 'admin',
  member = 'member',
  guest = 'guest',
  pending = 'pending',
  notMember = 'not_member',
}

export interface TeamMember {
  id: string
  permissions: Array<TeamMemberPermission>
  user: UserInfo
  createdAt: string
}

export interface TeamMemberRequest {
  userId: string
  permissions: Array<TeamMemberPermission>
}

export enum TeamMemberPermission {
  view = 'view',
  edit = 'edit',
  delete = 'delete',
  shareTo = 'share_to',
}

export interface TeamDraft {
  name: string
  description?: string
  contactEmail?: string
  isDiscoverable?: boolean
  approvableJoin?: boolean
}

export interface TeamCreateDraft extends TeamDraft {
  members?: Array<TeamMemberRequest>
  invites?: Array<TeamInviteRequest>
  // These fields are only used to preserve the draft state
  // while creation flow. Not used in real request
  discovery?: TeamDiscovery
  permission?: BackendPermissionOption<TeamMemberPermission>
}

export enum TeamDiscovery {
  private = 'private',
  public = 'public',
  open = 'open',
}

export interface TeamJoinRequest {
  id: string
  teamId: string
  createdAt: number
  status: TeamJoinRequestStatus
  user: UserInfo
  reason?: string
}

export enum TeamJoinRequestStatus {
  pending = 'pending',
  approved = 'approved',
  rejected = 'rejected',
  canceled = 'canceled',
}

export interface WalletBalances {
  balance: number
  unbounded_balance: number | null
}

export interface WalletUnitPurchaseInfo {
  stripe_public_key?: string
  products: { name: string; price: number; units: number }[]
}

export interface WalletUnitPurchaseSession {
  id: string
}

export enum WalletTransactionType {
  debit = 'debit',
  credit = 'credit',
}

export enum WalletTransactionKind {
  top_up = 'top_up',
  welcoming_bonus = 'welcoming_bonus',
  service_usage = 'service_usage',
  manual = 'manual',
  return = 'return',
}

export interface WalletTransaction {
  date: string
  amount: number
  type: WalletTransactionType
  kind: WalletTransactionKind
}

export type AdminWalletTransactionParams = Omit<WalletTransaction, 'date'>

export interface AdminWalletTransaction extends AdminWalletTransactionParams {
  id: string
  insertedAt: Date
}

export enum AdminUserRole {
  user = 'user',
  admin = 'admin',
}

export interface AdminUserSubscription {
  id: string
  title: string
}

export interface AdminUser {
  id: string
  name: string
  email: string
  userName: string
  signupDate: Date
  activeAt: Date
  balance: number
  role: AdminUserRole
  roles: AdminRole[]
  features?: Feature[]
  subscription: AdminUserSubscription | null
  isSuspended: boolean
  avatar?: string
  avatarIcon?: string
}

export enum Feature {
  pasa = 'pasa',
  print = 'print',
  publish = 'publish',
  schedule = 'schedule',
  trade = 'trade',
  strategies = 'strategies',
}

export interface AdminFeature {
  key: Feature
}

export interface AdminRole {
  id: string
  name: string
  features: { [key: string]: boolean }
  // These are not set-able from FE
  admin?: boolean
  default?: boolean
}

export interface AdminActivityLogBase {
  id: string
  insertedAt: Date
  user?: AdminUser
  metadata: unknown
}

export interface AdminActivityLogLogin extends AdminActivityLogBase {
  type: 'user.signed_in'
}

interface AdminActivityLogCommentMetadata {
  comment: { id: string }
  project_file: { name: string }
  project: { id: string; name: string }
  publication?: { slug: string; title: string }
}

export interface AdminActivityLogCommentCreated extends AdminActivityLogBase {
  type: 'comment.created'
  metadata: AdminActivityLogCommentMetadata
}

export interface AdminActivityLogCommentDeleted extends AdminActivityLogBase {
  type: 'comment.deleted'
  metadata: AdminActivityLogCommentMetadata
}

export interface AdminActivityLogRegister extends AdminActivityLogBase {
  type: 'user.signed_up'
}

export interface AdminActivityLogPageView extends AdminActivityLogBase {
  type: 'user.viewed_page'
  metadata: {
    url?: string
    title?: string
    client_data?: TrackingClientData
  }
}

export interface AdminActivityLogNotebookViewed extends AdminActivityLogBase {
  type: 'notebook.viewed'
  metadata: {
    id: string
    name: string
    client_data?: TrackingClientData
  }
}

export interface AdminActivityLogPublicationViewed extends AdminActivityLogBase {
  type: 'publication.viewed'
  metadata: {
    publication_id: string
    publication_name: string
    publication_type: string
    publication_slug: string
    project_id: string
    project_name: string
    project_file_id: string
    project_file_name: string
    client_data?: TrackingClientData
    referral_code?: string
    referral_id?: string
    referral?: AdminUser
  }
}

export interface AdminActivityLogDocumentationViewed extends AdminActivityLogBase {
  type: 'documentation.viewed'
  metadata: {
    documentation_id: string
    documentation_name: string
    documentation_data: IsleDefinition
    documentation_slug: string
    section_id: string
    client_data?: TrackingClientData
  }
}

export interface AdminActivityLogNotebookCreated extends AdminActivityLogBase {
  type: 'notebook.created'
  metadata: {
    id: string
    name: string
  }
}

export interface AdminActivityLogNotebookUpdated extends AdminActivityLogBase {
  type: 'notebook.updated'
  metadata: {
    id: string
    name: string
  }
}

export interface AdminActivityLogNotebookCloned extends AdminActivityLogBase {
  type: 'notebook.cloned'
  metadata: {
    id: string
    name: string
    clone_id: string
    clone_name: string
  }
}

export interface AdminActivityLogNotebookDeleted extends AdminActivityLogBase {
  type: 'notebook.deleted'
  metadata: {
    id: string
    name: string
  }
}

export interface AdminActivityLogNotebookAnalyzeViewed extends AdminActivityLogBase {
  type: 'notebook.analyze_viewed'
  metadata: {
    project_id: string
    project_name: string
  }
}

export interface AdminActivityLogNotebookPermissionsChanged extends AdminActivityLogBase {
  type: 'notebook.permissions_changed'
  metadata: {
    project_id: string
    project_name: string
    action: {
      new: Array<string>
      old: Array<string>
    }
    subject:
      | {
          type: 'user'
          data: AdminUser
        }
      | {
          type: 'team'
          data: {
            id: string
            name: string
          }
        }
  }
}

export interface AdminActivityLogNotebookOwnershipChanged extends AdminActivityLogBase {
  type: 'notebook.ownership_changed'
  metadata: {
    project_id: string
    project_name: string
    previous_owner?: AdminUser
    new_owner?: AdminUser
  }
}

export interface AdminActivityLogSubscribedToNewsletter extends AdminActivityLogBase {
  type: 'user.subscribed_to_newsletter'
  metadata: {
    email: string
    first_name: string
    last_name: string
  }
}

export type AdminActivityLog =
  | AdminActivityLogCommentCreated
  | AdminActivityLogCommentDeleted
  | AdminActivityLogLogin
  | AdminActivityLogRegister
  | AdminActivityLogPageView
  | AdminActivityLogNotebookViewed
  | AdminActivityLogNotebookCreated
  | AdminActivityLogNotebookUpdated
  | AdminActivityLogNotebookCloned
  | AdminActivityLogNotebookDeleted
  | AdminActivityLogNotebookAnalyzeViewed
  | AdminActivityLogNotebookPermissionsChanged
  | AdminActivityLogNotebookOwnershipChanged
  | AdminActivityLogDocumentationViewed
  | AdminActivityLogPublicationViewed
  | AdminActivityLogSubscribedToNewsletter

export type AdminActivityType = AdminActivityLog['type']
// this provides string hints but does not limit the type. so that we can write wildcards
export type AdminActivityFilter = AdminActivityType | string

export interface AdminFetchActivityOptions extends PaginationOptions {
  search?: string
  allUsers?: boolean
  types?: Array<AdminActivityFilter>
  userName?: string
  anonymous?: boolean
}

export type AdminFetchUsersSortField = 'name' | 'email' | 'user_name' | 'signup_date' | 'balance' | 'active_date'
export type AdminFetchUsersRole = 'user' | 'admin'
export type AdminFetchUsersStatus = 'active' | 'suspended'

export interface AdminFetchUsersOptions extends PaginationOptions, SortingOptions<AdminFetchUsersSortField> {
  search?: string
  planId?: string
  role?: AdminFetchUsersRole
  roleId?: string
  status?: AdminFetchUsersStatus
}

export type AdminUserAction = 'suspend' | 'unsuspend'

export interface AdminUserActionLog {
  id: string
  action: AdminUserAction
  insertedAt: Date
  user: AdminUser
  admin: AdminUser
  metadata: Record<string, unknown>
}

export interface AdminFetchUserActionLogsOption extends PaginationOptions {
  userId: string
}

export interface FeedEntry {
  id: string
  action: string
  target: {
    id: string
    type: string
    name: string
  }
  performedAt: string
  user: UserInfo
  performedBy?: UserInfo
}

export interface TeamInvite {
  id: string
  email: string
  permissions: Array<TeamMemberPermission>
  createdAt: string
}

export interface TeamInviteRequest {
  email: string
  permissions: Array<TeamMemberPermission>
}

export enum InviteLocationType {
  generic = 'generic',
  team = 'team',
  project = 'project',
}

export interface InviteLocationGeneric {
  type: InviteLocationType.generic
}

export interface InviteLocationTeam {
  type: InviteLocationType.team
  id: string
}

export interface InviteLocationProject {
  type: InviteLocationType.project
  id: string
}

export type InviteLocation = InviteLocationGeneric | InviteLocationTeam | InviteLocationProject

export interface Invite {
  id: string
  email: string
  createdAt: string
  to: InviteLocation
}

export interface TeamProject extends Project {
  teamPermissionId: string
}

export interface ProjectFileMetadata {
  notebook_errors?: Array<string>
}

export interface ProjectDirectoryMetadata {
  notebook_errors?: Record<string, number>
}

export interface WalletState extends WalletBalances, PaginatedResult<WalletTransaction> {}

export type NotebookCommentContent = JSONContent

export type NotebookCommentReaction = 'like' | 'dislike'

export interface NotebookComment {
  author: UserInfo
  createdAt: number
  id: string
  reactionByYou: NotebookCommentReaction | null
  likesCount: number
  dislikesCount: number
  replyTo: string
  content: NotebookCommentContent
  replies?: NotebookComment[]
  isDeleted: boolean
}

export interface FileUploadParams {
  clientName?: string
  clientMimeType?: string
}

export interface FileUploadItem {
  id: string
  url: string
  method: HTTPMethods
}

export interface UploadProjectParams {
  uploadId: FileUploadItem['id']
  name?: string
  verifyContent?: boolean
}

export interface UserFollow {
  follower: UserInfo
  followingByYou?: boolean
}

export interface GitHubConnection {
  isConnected: boolean
  userName: string | null
  avatar: string | null
}

export interface BinanceConnection {
  isConnected: boolean
}

export interface ProjectSyncResponse {
  localChanges: string[]
  remoteChanges: string[]
  syncConfig: ProjectSyncConfig
}

export interface NotificationEntry {
  id: string
  type: string
  createdAt: string
  metadata: Record<string, string>
  entity?: {
    id: string
    type: string
    name: string
    link: string
    slug: string
  }
  seenAt: string | null
  sourceUser?: UserInfo
  subjectUser?: UserInfo
  subjectTeam?: NotificationEntryTeam
}

export interface NotificationEntryTeam {
  id: string
  name: string
}

export enum PublicationType {
  canvas = 'canvas',
  notebook = 'notebook',
  pasa = 'pasa',
  isle = 'isle',
  strategy = 'strategy',
}
export enum PublicationsAccessLevel {
  // Only you can see this publication, nobody else have access to it
  draft = 'draft',
  // Publication is accessible if you provide a link to it
  link_shared = 'link_shared',
  // `link_shared` plus now users having access to the subject of
  // publication (e.g. a project) can find it in the list of publications
  collaborator_shared = 'collaborator_shared',
  // Publication is accessible to everyone
  public = 'public',
}
export type PublicationStatName = 'view_count' | 'share_count'

export interface Publication {
  id: string
  projectId: string
  fileId: string
  versionId: string
  type: PublicationType
  accessLevel: PublicationsAccessLevel
  publishedAt: string
  publishedChangesAt: string
  slug: string
  title: string

  viewCount: number
  shareCount: number
  tags: Array<Tag>
  authors: Array<UserInfo>
  description?: string
  coverImage?: string
  payload?: string
  pasaInfo?: {
    openApiSpec: OpenAPISpec
    portfolioAssets?: Array<NotebookStrategyAsset>
    portfolio1D?: number
    portfolio7D?: number
    portfolio30D?: number
    portfolioMax?: number
    portfolioSharpeRatio?: number
    portfolio1DChart?: Array<{ date: string; value: number }>
    portfolio7DChart?: Array<{ date: string; value: number }>
    portfolio30DChart?: Array<{ date: string; value: number }>
    portfolioMaxChart?: Array<{ date: string; value: number }>
    portfolio1DDays?: number
    portfolio7DDays?: number
    portfolio30DDays?: number
    portfolioMaxDays?: number
    portfolioAssetCategories?: Record<string, Array<string>>
    portfolioAssetPrices?: Record<string, number>
  }
  portfolios?: Array<ProjectBasicInfo>
  clapsCount?: number
  clappedByYou: boolean

  owner: UserInfo

  executionEnabled: boolean
  executionCopyProjectFiles: boolean

  externalProviders?: {
    sage?: {
      enabled: boolean
      id?: string
      url?: string
    }
    newsroom?: {
      enabled: boolean
    }
    documentation?: {
      enabled: boolean
    }
  }

  permissions?: Array<ProjectPermission>
}

export interface PublicationFetchParams {
  withPayload?: boolean
  withPermissions?: boolean
}

export interface PublicationUpdateParams {
  updateVersion?: boolean
  title?: string
  description?: string
  coverImage?: string
  accessLevel: PublicationsAccessLevel
  tagIds?: Array<string>
  externalProviders?: Publication['externalProviders']
  executionEnabled?: boolean
  executionCopyProjectFiles?: boolean
}

export interface ExternalPublishSettings {
  enabled: boolean
  cellIds: string[]
  title?: string
  printFormat: ExportFormat
  printTheme: ExportTheme
  printWidth: number
  layoutHeaderText: string | null
  layoutHeaderDate: boolean
  layoutFooterText: string | null
  slackSettings?: { enabled: boolean; channelName?: string; channelId?: string }
  emailSettings?: { enabled: boolean; emails?: string[] }
}

export enum AIGenerationType {
  input = 'input',
  code = 'code',
}

export enum AIGeneratedEntryType {
  input = 'input',
  code = 'code',
  markdown = 'content',
}

export enum AIGeneratedInputType {
  text = 'text',
  select = 'select',
  multiselect = 'multiselect',
  boolean = 'checkbox',
  number = 'number_field',
  slider = 'slider',
}

export interface AIGeneratedEntryMarkdown {
  type: AIGeneratedEntryType.markdown
  content: string
}

export interface AIGeneratedEntryCode {
  type: AIGeneratedEntryType.code
  source: string
}

export interface AIGeneratedInputBase {
  label: string
  variable: string
}

export interface AIGeneratedInputText extends AIGeneratedInputBase {
  type: AIGeneratedInputType.text
  default?: string
}

export interface AIGeneratedInputSelect extends AIGeneratedInputBase {
  type: AIGeneratedInputType.select
  default?: string
  options: Array<string>
}

export interface AIGeneratedInputMultiSelect extends AIGeneratedInputBase {
  type: AIGeneratedInputType.multiselect
  default?: Array<string>
  options: Array<string>
}

export interface AIGeneratedInputBoolean extends AIGeneratedInputBase {
  type: AIGeneratedInputType.boolean
  default?: boolean
}

export interface AIGeneratedInputNumber extends AIGeneratedInputBase {
  type: AIGeneratedInputType.number
  default?: number
}

export interface AIGeneratedInputSlider extends AIGeneratedInputBase {
  type: AIGeneratedInputType.slider
  default?: number
  min: number
  max: number
}

export type AIGeneratedInput =
  | AIGeneratedInputText
  | AIGeneratedInputSelect
  | AIGeneratedInputMultiSelect
  | AIGeneratedInputBoolean
  | AIGeneratedInputNumber
  | AIGeneratedInputSlider

export interface AIGeneratedEntryInput {
  type: AIGeneratedEntryType.input
  input: AIGeneratedInput
}

export type AIGeneratedEntry = AIGeneratedEntryCode | AIGeneratedEntryInput | AIGeneratedEntryMarkdown

export interface AIGeneratedCode {
  type: 'query' | 'problem'
  response: string
}

interface AIGeneratedPublicationsQuerySuccess {
  type: 'query'
  response: unknown
}

interface AIGeneratedPublicationsQueryQuestion {
  type: 'question'
  response: string
}

export type AIGeneratedPublicationsQuery = AIGeneratedPublicationsQuerySuccess | AIGeneratedPublicationsQueryQuestion

export interface AIGenerateSQLOpts {
  databaseName: string | undefined
  databaseTables: string
  allowMultipleTables: boolean
  snowflakeSchema?: string
}

export interface CreateProjectFileOptions {
  isDataBase64?: boolean
}

export interface UserAboutPage {
  contentRich: JSONContent | null
  contentRichDraft?: JSONContent | null
}

export interface CursorPaginatedResponse<T> {
  entries: T[]
  nextCursor: string
}

export interface BackendSlackChannel {
  id: string
  name: string
  isPrivate: boolean
}

export interface FetchSlackChannelsParams {
  cursor?: string
  limit?: number
  types?: Array<'public_channel' | 'private_channel'>
}

export interface Tip {
  id: Project['id']
  name: Project['name']
  description: Project['description']
  preview: Project['preview']
}

export interface CreateNewProjectSyncConfigParams {
  mode: 'new'
  repoName: string
  providerConfig?: ProjectSyncProviderConfig
  private?: boolean
}

export interface CreateExistingProjectSyncConfigParams {
  mode: 'existing'
  conflictsPrefer?: string
  provider: ProjectSyncProvider
  providerConfig?: ProjectSyncProviderConfig
  repo: string
  branch: string
  directory?: string
}

export type CreateProjectSyncConfigParams = CreateNewProjectSyncConfigParams | CreateExistingProjectSyncConfigParams

export interface BrowseSyncRemoteParams {
  provider: ProjectSyncProvider
  providerConfig?: ProjectSyncProviderConfig
  repo?: string
  branch?: string
  directory?: string
}

export interface BrowseSyncRemoteResponse {
  entries: string[]
}

export interface FetchSelfOptions {
  forceRefresh?: boolean
  idmToken?: string
  telegramInitData?: string
  telegramOAuthData?: string
  referralCode?: string
  sessionContinue?: string
}

export interface SyncProjectOpts {
  prefer?: 'local' | 'remote'
}

export interface FetchProjectFileOptions {
  publicationId?: string
  versionId?: string
  withData?: boolean
}

export interface AppStatus {
  versionCommit?: string
  versionCommitShort?: string
  versionTag?: string
}

export interface InstanceExportOpts {
  instanceId: string
  cellId: string
  outputIndex: number
  fileName: string
  saveInProject?: boolean
}

export interface TradingAssetsOpts {
  remoteType?: string
  includeRemoteIds?: string[]
  search?: string
}

export interface TradingAssetDetailsOpts {
  remoteId: string
  remoteType?: string
}

export interface TradingAssetChartOpts extends TradingAssetDetailsOpts {
  interval?: 'daily' | 'hourly'
}

export interface TradingAsset {
  remoteType: string
  remoteId: string
  symbol: string
  description: string
  imageUrl?: string
  marketCap?: number
  price?: number
  age?: number
}

export interface TradingAssetData {
  marketCaps: Array<[number, number]>
  prices: Array<[number, number]>
  totalVolumes: Array<[number, number]>
}

export interface TradingExchangeOpts {
  exchange: ExchangeType
}

export interface TradingBalance {
  asset: string
  free: number
  total: number
  used: number
}

interface SubscriptionBaseStat {
  strategyId: string
  strategyTitle: string
  strategySlug: string
  strategyImage?: string
}

export interface SubscriptionView extends SubscriptionBaseStat {
  views: number
}

export interface SubscriptionSub extends SubscriptionBaseStat {
  subscriptions: number
}

export interface SubscriptionStats {
  views: SubscriptionView[]
  subscriptions: SubscriptionSub[]
}

export interface SignupStat {
  id: string
  firstName: string
  lastName: string
  userName: string
}

export interface AdminFetchSubStats extends PaginationOptions {
  search?: string
}

export interface AdminSubscriptionsStat {
  userId: string
  userName: string
  userFirstName: string
  userLastName: string
  userReferralCode: string
  userEmail: string
  views: number
  subscriptions: number
}

export interface AdminSubscriptionStatsResult extends PaginatedResult<AdminSubscriptionsStat> {
  totalViews: number
  totalSubs: number
}

export interface TradingBotChannel {
  id: string
  insertedAt: string
  type: 'private' | 'channel' | 'group'
  title: string
  telegramChatId: string
  periodicUpdatesEnabled: boolean
  periodicUpdatesInterval: 'daily' | 'weekly'
}

export enum AdminWatermarkTheme {
  light = 'light',
  dark = 'dark',
}

export interface Backend {
  withSignal(signal?: AbortSignal): Backend

  isCloneProjectSupported(): boolean
  isProjectsSupported(): boolean
  isRunsSupported(): boolean
  isTeamsSupported(): boolean
  isCodeCommentingSupported(): boolean
  isCollaboratorsSupported(): boolean
  isGitHubSupported(): boolean
  isGitHubCustomGlobalTokenSupported(): boolean
  isSlackSupported(): boolean
  isPricingSupported(): boolean
  isWalletSupported(): boolean
  isNewsroomSupported(): boolean
  isPublicationsSupported(): boolean
  isUpdatingMainFileSupported(): boolean
  isExecutingMainFileSupported(): boolean
  isExecutionWithParametersSupported(): boolean
  isProjectRatingSupported(): boolean
  isProjectLicenseSupported(): boolean
  isProjectAnalysisSupported(): boolean
  isSecretsSupported(): boolean
  isCollaborationConnectionSupported(): boolean
  isExecutionConnectionSupported(): boolean
  isContainerConnectionSupported(): boolean
  isNotebookConnectionSupported(): boolean
  isScheduleEnabled(): boolean
  isFileUrlByNameSupported(): boolean
  isEventsSupported(): boolean
  isNotificationsWSSupported(): boolean
  isProjectDescriptionSupported(): boolean
  isProjectCategoriesSupported(): boolean
  isEthereumIDESupported(): boolean
  isNewsletterSubscriptionSupported(): boolean

  isNotificationsSupported(): boolean
  isDataRequestSupported(): boolean

  isFollowersSupported(): boolean

  isInviteSupported(): boolean

  isAdminSupported(): boolean

  isAboutProjectSupported(): boolean
  isExportProjectSupported(): boolean

  // cannot be called "is" because any "is" must not take parameters
  canExecuteFile(file: File): boolean

  //
  fetchPublications(opts: PublicationPaginationOptions): Promise<PublicationPaginatedResult>
  fetchPublication(fileId: string): Promise<Publication>
  fetchPublicationBySlug(slug: string, opts?: PublicationFetchParams): Promise<Publication>
  sendUpdatePublication(fileId: string, attrs: PublicationUpdateParams): Promise<Publication>
  sendUpdatePublicationStat(fileId: string, statName: PublicationStatName): Promise<Publication>
  publicationSortingInfo(): BackendSortingInfo
  strategySortingInfo(): BackendSortingInfo

  fetchExternalPublicationSettings(fileId: string): Promise<ExternalPublishSettings>
  sendUpdateExternalPublicationSettings(fileId: string, update: ExternalPublishSettings): Promise<ExternalPublishSettings>

  fetchFileEmbed(projectId: string, fileId: string): Promise<FileEmbed>
  sendUpdateFileEmbed(projectId: string, fileId: string, options: FileEmbedOptions): Promise<FileEmbed>
  sendRevokeFileEmbed(projectId: string, fileId: string): Promise<void>

  //
  projectSortingInfo(): BackendSortingInfo
  fileUrlByName(projectId: string, name: string): string

  //
  createCollaborationConnection(fileId: FileId): BackendCollaborationConnection
  createExecutionConnection(executionId: ExecutionId): BackendExecutionConnection
  createContainerConnection(projectId: string): BackendContainerConnection
  createNotebookConnection(projectId: string, fileId: string, params: BackendNotebookConnectionParameters): BackendNotebookConnection
  createNotebookEmbedConnection(embedId: string, params: BackendNotebookConnectionParameters): BackendNotebookConnection
  createCellEmbedConnection(
    projectId: string,
    fileId: string,
    cellId: string,
    outputIndex?: string,
    mode?: BackendCellEmbedConnectionMode,
  ): BackendCellEmbedConnection
  createNotificationsConnection(userId: string): BackendNotificationsConnection
  createProjectImportConnection(): BackendProjectImportConnection
  createCollaboratorLobbyConnection(projectId: string): BackendCollaboratorLobbyConnection
  createFileChannelConnection(projectId: string, fileId: string): BackendFileChannelConnection
  createTrackingConnection(): BackendTrackingConnection
  createTradingTransactionsConnection(projectId?: string): BackendTradingTransactionsConnection

  //
  fetchProjectUserPermissions(projectId: string, opts: FetchPermissionsPaginationOptions): Promise<PaginatedResult<ProjectUserAccess>>
  fetchProjectTeamPermissions(projectId: string, opts: FetchPermissionsPaginationOptions): Promise<PaginatedResult<ProjectTeamAccess>>
  fetchUser(username: string): Promise<UserInfo>
  fetchUsers(search?: string): Promise<UserInfo[]>

  sendUpdateProjectPublicity(projectId: string, isPublic: boolean): Promise<Project>

  sendUpdateAboutPage(contentRich: object | null, contentRichDraft: object | null): Promise<void>
  fetchAboutPage(userId: string): Promise<UserAboutPage>

  fetchTips(): Promise<ResponseCollection<Tip>>

  fetchSelf(opts?: FetchSelfOptions): Promise<Self>
  fetchSubscriptionStats(): Promise<SubscriptionStats>
  fetchSignupStats(): Promise<SignupStat[]>

  sendLogOut(): Promise<void>

  //
  fetchAppStatus(): Promise<AppStatus>
  fetchGitHubConnection(): Promise<GitHubConnection>
  fetchBinanceConnection(): Promise<BinanceConnection>
  fetchBalance(): Promise<Balance>
  fetchProfile(): Promise<Profile>
  fetchSettings(): Promise<Record<string, any>>
  fetchProjects(opts: ProjectPaginationOptions): Promise<ProjectPaginatedResult>
  fetchProject(id: string, withMainFile?: boolean): Promise<Project>
  fetchFile(projectId: string, fileId: string): Promise<File>
  fetchFileExists(id: string, path: string): Promise<boolean>
  fetchDirectoryExists(id: string, path: string): Promise<boolean>
  fetchProjectFiles(id: string, path?: string): Promise<Array<File>>
  fetchProjectFileVersions(id: string, fileId: FileId): Promise<Array<FileVersion>>
  fetchProjectDirectories(id: string, filter?: Array<string>): Promise<Array<File>>
  fetchLatestProjectFileVersion(id: string, fileId: FileId, options?: FetchProjectFileOptions): Promise<FileVersion>
  fetchLatestProjectFileVersionRaw(id: string, fileId: FileId, options?: FetchProjectFileOptions): Promise<Blob>
  fetchLatestProjectFileVersionUrl(id: string, fileId: FileId, options?: FetchProjectFileOptions): Promise<string>
  fetchProjectMainFileVersions(id: string): Promise<PaginatedResult<FileVersion>>
  fetchProjectMainFileVersion(id: string, versionId: string): Promise<FileVersion>
  fetchProjectFileVersion(projectId: string, fileId: FileId, versionId: string, withData?: boolean): Promise<FileVersion>
  fetchExecutions(id: string, fileId: FileId): Promise<Array<ExecutionSummary>>
  fetchPaginatedExecutions(id: string, fileId: FileId, opts?: FetchExecutionsOptions): Promise<PaginatedResult<Execution>>
  fetchExecution(id: string, fileId: FileId, executionId: ExecutionId): Promise<Execution>
  sendConnectToGitHub(code: string, redirectUri: string): Promise<GitHubConnection>
  sendDisconnectFromGitHub(): Promise<void>
  sendConnectToBinance(key: string, secret: string): Promise<BinanceConnection>
  sendDisconnectFromBinance(): Promise<void>
  sendUpdateSettings(settings: Record<string, any>): Promise<void>
  sendCreateProject(data: Partial<Project>, content?: Array<unknown>, shouldCreateMainFile?: boolean): Promise<Project>
  sendUploadProject(data: UploadProjectParams): Promise<Project>
  sendReplaceProject(id: string, data: Project): Promise<Project>
  sendDeleteProject(id: string): Promise<void>
  sendCloneProject(projectId: string): Promise<Project>
  sendCreateProjectFile(id: string, data: Partial<File>, opts?: CreateProjectFileOptions): Promise<File>
  sendCreateProjectFileVersion(
    id: string,
    fileId: FileId,
    textOrBase64IfBinary: string,
    binary: boolean,
    threads?: CodeComments,
  ): Promise<FileVersion>
  sendUpdateProjectFile(projectId: string, data: Partial<File>): Promise<File>
  sendDeleteProjectFile(projectId: string, fileId: FileId): Promise<void>
  sendDuplicateProjectFile(projectId: string, fileId: FileId, name?: string): Promise<File>
  sendReplaceProjectDirectory(id: string, oldName: string, newName: string): Promise<Array<File>>
  sendDeleteProjectDirectory(id: string, path: string): Promise<void>
  sendUpvoteProject(projectId: string): Promise<number>
  grantUserPermissions(projectId: string, userId: string, permissions: string[]): Promise<ProjectUserAccess>
  revokeUserPermissions(projectId: string, userId: string): Promise<ProjectUserAccess>
  grantTeamPermissions(projectId: string, teamId: string, permissions: string[]): Promise<ProjectTeamAccess>
  revokeTeamPermissions(projectId: string, teamId: string): Promise<ProjectTeamAccess>
  sendStartExecution(id: string, fileId: FileId, settings?: ExecutionSettings): Promise<Execution>
  sendStopExecution(id: string, fileId: FileId, executionId: ExecutionId): Promise<void>

  sendUpdateProjectOwner(projectId: string, userId: string): Promise<Project>

  updateProjectUserPermissions(projectId: string, permissionsId: string, permissions: string[]): Promise<ProjectUserAccess>
  updateProjectTeamPermissions(projectId: string, permissionsId: string, permissions: string[]): Promise<ProjectTeamAccess>
  toggleProjectPermissionAuthor(projectId: string, permissionId: string, author: boolean): Promise<void>

  fetchTags(): Promise<Tag[]>
  sendUpdateProjectTags(projectId: string, tags: string[]): Promise<Project>
  sendUpdateProjectThumbnail(projectId: string, data: FormData): Promise<Project>

  fetchSubscriptionHistory(): Promise<Subscription[]>
  fetchSubscription(): Promise<Subscription | null>
  changeSubscription(id: string): Promise<Subscription | null>
  cancelSubscription(): Promise<Subscription | null>

  fetchNotebookComments(projectId: string, fileId: string): Promise<PaginatedResult<NotebookComment>>
  addNotebookComment(projectId: string, fileId: string, replyTo: string, content: NotebookCommentContent): Promise<NotebookComment | null>
  deleteNotebookComment(projectId: string, commentId: string): Promise<NotebookComment | null>
  sendAddNotebookCommentReaction(projectId: string, commentId: string, reaction: NotebookCommentReaction): Promise<NotebookComment | null>
  sendRemoveNotebookCommentReaction(projectId: string, commentId: string): Promise<NotebookComment | null>
  reportNotebookComment(projectId: string, commentId: string): Promise<NotebookComment | null>
  editNotebookComment(projectId: string, commentId: string, content: NotebookCommentContent): Promise<NotebookComment | null>

  fetchScheduledNotebooks(opts: ScheduledNotebookListingOptions): Promise<PaginatedResult<ScheduledNotebook>>
  fetchUserServers(opts: ScheduledNotebookListingOptions): Promise<PaginatedResult<UserServer>>
  sendStopUserServer(serverId: string): Promise<void>
  fetchNotebookSchedule(projectId: string, fileId: string): Promise<ScheduledExecution>
  sendNotebookSchedule(projectId: string, fileId: string, data: ScheduledExecutionPatch): Promise<ScheduledExecution>

  fetchPublicationSchedule(fileId: string): Promise<PublicationSchedule>
  sendUpdatePublicationSchedule(fileId: string, update: PublicationSchedule): Promise<PublicationSchedule>

  fetchSecrets(): Promise<Secret[]>
  saveSecret(key: string, secret: string): Promise<Secret>
  deleteSecret(id: string): Promise<Secret>

  fetchNotebookAnalysisProvenance(id: string, direction: NotebookAnalysisDirection): Promise<NotebookAnalysis>
  fetchNotebookAnalysisInheritance(id: string): Promise<NotebookAnalysis>

  fetchReferrals(): Promise<Array<Referral>>
  fetchReferralUsers(referralId: string, opts: PaginationOptions): Promise<PaginatedResult<ReferralUser>>
  sendCreateReferral(referral: ReferralUpdate): Promise<Referral>
  sendUpdateReferral(id: string, update: Partial<ReferralUpdate>): Promise<Referral>
  sendDeleteReferral(id: string): Promise<void>

  fetchDocumentation(): Promise<Array<DocumentationSection>>
  sendUpdateDocumentationSection(id: string, update: Partial<DocumentationSectionUpdate>): Promise<DocumentationSection>

  fetchNewsroom(): Promise<Array<NewsroomEntry>>
  fetchNewsroomEntry(id: string): Promise<NewsroomEntry>
  sendCreateNewsroomEntry(entry: NewsroomEntryUpdate): Promise<NewsroomEntry>
  sendUpdateNewsroomEntry(id: string, update: Partial<NewsroomEntryUpdate>): Promise<NewsroomEntry>
  sendDeleteNewsroomEntry(id: string): Promise<void>

  getNotificationsCount(): Promise<number | undefined>
  sendAddEventEntry(eventData: EventData): Promise<EventEntry>

  fetchToken(): Promise<UserToken>
  createOAuthState(reason: string): Promise<string>

  verifySlackCode(code: string, state: string, reason: string, redirectUri: string): Promise<void>
  disconnectSlack(): Promise<void>
  fetchSlackChannels(params?: FetchSlackChannelsParams): Promise<CursorPaginatedResponse<BackendSlackChannel>>

  fetchProjectAccessRequests(projectId: string, opts: PaginationOptions): Promise<PaginatedResult<ProjectAccessRequest>>
  fetchProjectAccessRequest(projectId: string, id: string): Promise<ProjectAccessRequest>
  fetchMyProjectAccessRequest(projectId: string): Promise<ProjectAccessRequest>
  sendCreateProjectAccessRequest(projectId: string, reason: string): Promise<ProjectAccessRequest>
  sendDeleteProjectAccessRequest(projectId: string, id: string): Promise<ProjectAccessRequest>
  sendApproveProjectAccessRequest(projectId: string, id: string): Promise<ProjectAccessRequest>
  sendRejectProjectAccessRequest(projectId: string, id: string): Promise<ProjectAccessRequest>

  fetchTeams(opts: FetchTeamsPaginationOptions): Promise<PaginatedResult<Team>>
  fetchTeam(teamId: string): Promise<Team>
  sendCreateTeam(data: TeamCreateDraft, isCheckOnly: boolean): Promise<Team>
  sendUpdateTeam(teamId: string, data: Partial<TeamDraft>): Promise<Team>
  sendUpdateTeamThumbnail(teamId: string, data: FormData): Promise<Team>
  sendDeleteTeam(teamId: string): Promise<void>
  fetchTeamMembers(teamId: string, opts: FetchMembersPaginationOptions): Promise<PaginatedResult<TeamMember>>
  sendCreateTeamMember(teamId: string, userId: string, permissions: Array<TeamMemberPermission>): Promise<TeamMember>
  sendUpdateTeamMember(teamId: string, memberId: string, permissions: Array<TeamMemberPermission>): Promise<TeamMember>
  sendRemoveTeamMember(teamId: string, memberId: string): Promise<TeamMember>
  fetchTeamJoinRequests(teamId: string, opts: PaginationOptions): Promise<PaginatedResult<TeamJoinRequest>>
  fetchActiveTeamJoinRequest(teamId: string): Promise<TeamJoinRequest>
  sendRespondTeamJoinRequest(teamId: string, joinRequestId: string, isApproved: boolean): Promise<TeamJoinRequest>
  sendCreateTeamJoinRequest(teamId: string, reason?: string): Promise<TeamJoinRequest>
  sendCancelTeamJoinRequest(teamId: string, joinRequestId: string): Promise<void>
  sendLeaveTeam(teamId: string): Promise<Team>
  fetchTeamProjects(teamId: string, opts: ProjectPaginationOptions): Promise<PaginatedResult<TeamProject>>
  fetchTeamActivity(teamId: string, opts: PaginationOptions): Promise<PaginatedResult<FeedEntry>>
  teamSortingInfo(): BackendSortingInfo
  teamDiscoveryInfo(isAdmin: boolean): BackendSelectInfo<TeamDiscovery>
  teamPermissionInfo(): BackendPermissionInfo<TeamMemberPermission>
  sendUpdateTeamOwner(teamId: string, userId: string): Promise<Team>

  fetchWallet(opts: FetchWalletPaginationOptions): Promise<WalletState>
  sendTransferUnits(amount: number): Promise<WalletBalances>
  fetchWalletUnitPurchaseInfo(): Promise<WalletUnitPurchaseInfo>
  sendCreateWalletUnitPurchaseSession(units: number): Promise<WalletUnitPurchaseSession>

  fetchProjectInvites(projectId: string, opts: PaginationOptions): Promise<ResponseCollection<ProjectInvite>>
  sendCreateProjectInvite(projectId: string, email: string, permissions: Array<NotebookPermission>): Promise<ProjectInvite>
  sendDeleteProjectInvite(projectId: string, inviteId: string): Promise<ProjectInvite>

  fetchTeamInvites(teamId: string, opts: FetchMembersPaginationOptions): Promise<ResponseCollection<TeamInvite>>
  sendCreateTeamInvite(teamId: string, email: string, permissions: Array<TeamMemberPermission>): Promise<TeamInvite>
  sendDeleteTeamInvite(teamId: string, inviteId: string): Promise<TeamInvite>

  sendCreateInvite(email: string, invitationMessage?: string): Promise<Invite>
  fetchInvite(id: string): Promise<Invite>

  sendDataRequest(message: string): Promise<void>

  sendUploadRequest(params?: FileUploadParams): Promise<FileUploadItem>
  sendUploadContent(upload: FileUploadItem, content: string | ArrayBuffer | Blob): Promise<void>

  fetchFollowers(userId: string, type: string, opts: PaginationOptions): Promise<PaginatedResult<UserFollow>>
  sendFollowUser(userId: string): Promise<UserFollow>
  sendUnfollowUser(userId: string): Promise<UserFollow>

  fetchRatePlans(): Promise<Array<RatePlan>>

  syncProject(projectId: string, syncConfigId: string, action: string, opts?: SyncProjectOpts): Promise<ProjectSyncResponse>
  sendCreateProjectSyncConfig(projectId: string, params: CreateProjectSyncConfigParams): Promise<ProjectSyncResponse>
  sendDeleteProjectSyncConfig(projectId: string, syncConfigId: string): Promise<void>
  fetchBrowseSyncRemote(projectId: string, params: BrowseSyncRemoteParams): Promise<BrowseSyncRemoteResponse>

  fetchExportProjectURL(projectId: string, fileIds?: string[]): string
  fetchExportProjectFile(projectId: string, fileId: string, opts?: ExportProjectFileOpts): Promise<Blob>
  fetchExportProjectFileURL(projectId: string, fileId: string, opts?: ExportProjectFileOpts): string
  fetchNotifications(opts: NotificationsParams): Promise<PaginatedResult<NotificationEntry>>
  sendMarkNotificationsAsRead(): Promise<void>

  fetchTagSubscriptions(): Promise<ResponseCollection<TagSubscription>>
  sendSubscribeToTag(tagId: string, email: string): Promise<TagSubscription>
  sendUnsubscribeFromTag(subscriptionId: string): Promise<TagSubscription>

  sendSubscribeToNewsletter(firstName: string, lastName: string, email: string): Promise<void>

  sendAIGenerate(query: string, type: AIGenerationType): Promise<Array<AIGeneratedEntry>>
  sendAIGenerateSQL(
    query: string,
    databaseType: 'mysql' | 'postgresql' | 'mssql' | 'snowflake' | 'generic',
    opts: AIGenerateSQLOpts,
  ): Promise<AIGeneratedCode>
  sendAIGenerateChart(query: string, dataTypeInformation: string, variableName: string): Promise<AIGeneratedCode>
  sendAIGeneratePublicationsQuery(query: string, mode?: ViewMode): Promise<AIGeneratedPublicationsQuery>

  fetchTradingTransaction(id: string): Promise<TradingTransaction>
  sendCreateTradingTransaction(template: Partial<TradingTransaction>): Promise<TradingTransaction>

  sendAddPublicationClap(fileId: string): Promise<void>
  sendRemovePublicationClap(fileId: string): Promise<void>

  generateInstanceExportURL(opts: InstanceExportOpts): string
  sendSaveInstanceExport(opts: InstanceExportOpts): Promise<void>

  fetchTradingAssets(opts?: TradingAssetsOpts): Promise<TradingAsset[]>
  fetchTradingAsset(opts: TradingAssetDetailsOpts): Promise<TradingAsset>
  fetchTradingAssetData(opts: TradingAssetChartOpts): Promise<TradingAssetData>

  fetchTradingBalance(opts: TradingExchangeOpts): Promise<TradingBalance[]>
  fetchTradingDemoMarkets(): Promise<Dictionary<Market>>
  fetchTradingDemoTickers(): Promise<Tickers>

  adminFetchActivity(opts: AdminFetchActivityOptions): Promise<PaginatedResult<AdminActivityLog>>
  adminFetchUser(userId: string): Promise<AdminUser>
  adminFetchUsers(opts: AdminFetchUsersOptions): Promise<PaginatedResult<AdminUser>>
  adminFetchFeatures(): Promise<AdminFeature[]>
  adminFetchRoles(): Promise<AdminRole[]>
  adminSendCreateRole(role: AdminRole): Promise<AdminRole>
  adminSendUpdateRole(role: AdminRole): Promise<AdminRole>
  adminSendDeleteRole(role: AdminRole): Promise<void>
  adminFetchUserRoles(userId: string): Promise<AdminRole[]>
  adminSendAddUserRole(userId: string, roleId: string): Promise<void>
  adminSendRemoveUserRole(userId: string, roleId: string): Promise<void>
  adminSendUserRole(userId: string, role: AdminUserRole): Promise<AdminUser>
  adminSendUserSubscription(userId: string, subscriptionId: string): Promise<AdminUser>
  adminFetchUserWalletTransactions(userId: string, opts: PaginationOptions): Promise<PaginatedResult<AdminWalletTransaction>>
  adminSendUserWalletTransaction(userId: string, params: AdminWalletTransactionParams): Promise<AdminWalletTransaction>
  adminSendImpersonateUser(userId: string): Promise<Self>
  adminSendUnimpersonate(): Promise<Self>
  adminSendSuspend(userId: string, message: string): Promise<AdminUser>
  adminSendUnsuspend(userId: string, message: string): Promise<AdminUser>
  adminFetchUserActionLogs(opts: AdminFetchUserActionLogsOption): Promise<PaginatedResult<AdminUserActionLog>>
  adminFetchSubsctiptionStats(opts: AdminFetchSubStats): Promise<AdminSubscriptionStatsResult>
  adminFetchTradingBotChannels(): Promise<TradingBotChannel[]>
  adminSendUpdateTradingBotChannel(channelId: string, attrs: Partial<TradingBotChannel>): Promise<TradingBotChannel>
  adminSendSendTradingBotChannelPeriodicUpdate(channelId: string): Promise<TradingBotChannel>
  adminSendRefreshTradingBotChannel(channelId: string): Promise<TradingBotChannel>
  adminSendDeleteTradingBotChannel(channelId: string): Promise<TradingBotChannel>

  adminFetchWatermarkUrl(theme: AdminWatermarkTheme): Promise<string>
  adminSendWatermark(theme: AdminWatermarkTheme, watermarkFileData: Blob): Promise<void>
  adminDeleteWatermark(theme: AdminWatermarkTheme): Promise<void>
}
